var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import 'react-loading-skeleton/dist/skeleton.css';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import AgentPreviewAvatarBackground from 'src/images/agent/AgentPreviewAvatarBackground.png';
import styles from './styles.module.scss';
const Image = (_a) => {
    var { src, defaultSrc, isCardImage = false, isBoardImage = false, setShowSkeletonLoader } = _a, props = __rest(_a, ["src", "defaultSrc", "isCardImage", "isBoardImage", "setShowSkeletonLoader"]);
    const [currentSrc, setCurrentSrc] = useState(src || defaultSrc || '');
    const [loading, setLoading] = useState(true);
    const onSrcError = () => {
        if (currentSrc === src && defaultSrc) {
            setCurrentSrc(defaultSrc);
        }
    };
    const handleImageLoad = () => {
        setLoading(false); // Stop the loading indicator
        setShowSkeletonLoader && setShowSkeletonLoader(false);
    };
    useEffect(() => {
        setCurrentSrc(src || defaultSrc || '');
    }, [src, defaultSrc, setCurrentSrc]);
    if (!currentSrc)
        return null;
    return (_jsxs("div", { className: cn({
            [styles['image-container']]: isCardImage,
            [styles['image-board-container']]: isBoardImage,
            [styles['image-container-default']]: !isCardImage,
        }), children: [loading && (isCardImage || isBoardImage) && (_jsx(Skeleton, { height: 250, width: isBoardImage ? 550 : 200, style: {
                    backgroundImage: `url(${AgentPreviewAvatarBackground})`, // Background image
                    backgroundSize: 'cover', // Make sure the background image covers the skeleton
                    filter: 'brightness(0.5)', // Darken the background image with a filter
                } })), _jsx("img", Object.assign({ src: currentSrc, alt: props.alt, onError: onSrcError, onLoad: handleImageLoad, style: { visibility: !loading ? 'visible' : 'hidden' } }, props))] }));
};
export default Image;
