import { jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState, } from 'react';
// Creating the context, initialized as undefined. This will be provided to components
// that need to access the sidebar width.
const WidthContext = createContext(undefined);
// Custom hook to access the current width of the sidebar
export const useSideBarWidth = () => {
    const context = useContext(WidthContext);
    // If the context is not found, it means the hook is being used outside the provider,
    // which throws an error.
    if (!context) {
        throw new Error('useSideBarWidth must be used within a WidthProvider');
    }
    return context.width; // Returning the current width of the sidebar
};
// Custom hook to set the width of the sidebar
export const useSetSideBarWidth = () => {
    const context = useContext(WidthContext);
    // If the context is not found, it means the hook is being used outside the provider,
    // which throws an error.
    if (!context) {
        throw new Error('useSetSideBarWidth must be used within a WidthProvider');
    }
    return context.setWidth; // Returning the function to update the sidebar width
};
// The WidthProvider component provides the context to its children
// so that they can access and modify the sidebar width.
export const WidthProvider = ({ children }) => {
    // State to hold the sidebar width, initialized to 0
    const [width, setWidth] = useState(0);
    // useEffect hook to load the sidebar width from localStorage when the component mounts
    useEffect(() => {
        const storedWidth = localStorage.getItem('sidebarWidth');
        // If a width is stored in localStorage, set it to the state
        if (storedWidth) {
            setWidth(parseInt(storedWidth, 10));
        }
    }, []); // Empty dependency array means this effect runs only once when the component mounts
    return (
    // The WidthContext.Provider makes the current width and the setWidth function
    // available to all components within this provider.
    _jsxs(WidthContext.Provider, { value: { width, setWidth }, children: [children, " "] }));
};
