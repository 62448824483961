import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import useClasses from './hooks/useClasses';
const Modal = ({ open, onClose, children, title, classes, }) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        if (open) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = '';
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            document.body.style.overflow = '';
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onClose]);
    const componentStyles = useClasses({ classes });
    if (!open)
        return null;
    return ReactDOM.createPortal(_jsx("div", { className: componentStyles.modalOverlay, onClick: onClose, "aria-modal": true, children: _jsxs("div", { className: componentStyles.modalContent, onClick: (e) => e.stopPropagation(), children: [title && _jsx("div", { className: componentStyles.contentTitle, children: title }), _jsx("div", { className: componentStyles.contentWrapper, children: children })] }) }), document.body);
};
export default Modal;
