var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alteraApi } from 'src/api/rtk';
import { useGetCurrentUserQuery, useLazyUserLogoutQuery, useRefreshSessionMutation, } from 'src/api/rtk/services/user';
import { USER_TAGS } from 'src/api/rtk/services/user';
import { useIsUserAuthenticated } from 'src/hooks/user';
import { selectAuthSessionExpiresAt } from 'src/redux/features/userSlice';
const REFRESH_THRESHOLD = 60 * 60 * 1000; // 1 hour
const useGetCurrentUser = () => {
    const dispatch = useDispatch();
    const isUserAuthenticated = useIsUserAuthenticated();
    const authSessionExpiresAt = useSelector(selectAuthSessionExpiresAt);
    const [refreshSession] = useRefreshSessionMutation();
    const [logoutUser] = useLazyUserLogoutQuery();
    const checkAndRefreshSession = useCallback(() => {
        if (!authSessionExpiresAt)
            return;
        const expiresAt = new Date(authSessionExpiresAt);
        const now = new Date();
        const timeUntilExpiration = expiresAt.getTime() - now.getTime();
        // Refresh session if it's about to expire within the threshold
        if (timeUntilExpiration > 0 && timeUntilExpiration <= REFRESH_THRESHOLD) {
            refreshSession({});
        }
    }, [authSessionExpiresAt, refreshSession]);
    useEffect(() => {
        checkAndRefreshSession();
        const intervalId = setInterval(checkAndRefreshSession, REFRESH_THRESHOLD); // Check every hour
        return () => clearInterval(intervalId);
    }, [checkAndRefreshSession]);
    useEffect(() => {
        const showSessionExpiredModalHandler = () => __awaiter(void 0, void 0, void 0, function* () {
            // todo add session expired warning modal
            yield logoutUser({}).unwrap();
        });
        let timeoutId;
        if (isUserAuthenticated && authSessionExpiresAt) {
            const currentDt = new Date();
            const expirationDt = new Date(authSessionExpiresAt);
            const timeDifference = +expirationDt - +currentDt;
            timeoutId = setTimeout(() => {
                void showSessionExpiredModalHandler();
            }, timeDifference);
        }
        return () => {
            if (timeoutId)
                clearTimeout(timeoutId);
        };
    }, [authSessionExpiresAt, isUserAuthenticated, logoutUser]);
    // fetch periodically actual user info
    useEffect(() => {
        let intervalId;
        if (isUserAuthenticated) {
            intervalId = setInterval(() => {
                dispatch(alteraApi.util.invalidateTags([USER_TAGS.profileInfo]));
            }, 5 * 60 * 1000);
        }
        return () => {
            if (intervalId)
                clearInterval(intervalId);
        };
    }, [isUserAuthenticated, dispatch]);
    return useGetCurrentUserQuery({}, {
        skip: !isUserAuthenticated,
    });
};
export default useGetCurrentUser;
