var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLazyUserLogoutQuery } from 'src/api/rtk/services/user';
import Avatar, { AvatarSize } from 'src/components/Avatar';
import MenuButton from 'src/components/Buttons/MenuButton';
import Modal from 'src/components/Modals'; // Import the existing Modal component
import JoinProModal from 'src/components/Modals/ModalsContent/JoinProModal'; // Import the new JoinProModal content
import { handleUpgradeSubscription } from 'src/components/Subscription/SubscriptionUpgrade';
// TODO: Turn back on dark and light mode
// import {MIXPANEL_EVENTS, MIXPANEL_PROPERTIES} from 'src/constants/mixpanel';
//
// import {mixpanelClient} from 'src/services/mixpanel';
import { useSetSideBarWidth } from 'src/context/SideBarWidthContext';
import { useTheme } from 'src/context/ThemeContext';
import { sideBarMokData } from 'src/layout/sideBar/common';
import { setGlobalFilters } from 'src/redux/features/agentSlice/agentSlice'; // Adjust the import path as needed
import { setShowSystemModal, SYSTEM_MODALS, } from 'src/redux/features/applicationSlice';
import { PAGES } from 'src/router/pages';
import style from './styles.module.scss';
export const SideBar = () => {
    const navigate = useNavigate();
    const { theme } = useTheme(); // add toggleTheme after turn back on dark and light mode
    const user = useSelector((state) => state.userSlice.profile);
    const [activeParentLink, setActiveParentLink] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [logoutUser] = useLazyUserLogoutQuery();
    const dispatch = useDispatch();
    const [isJoinProModalOpen, setIsJoinProModalOpen] = useState(false);
    const sidebarRef = useRef(null); // Ref for sidebar container
    const setSideBarWidth = useSetSideBarWidth(); // Use the hook to get the width
    useEffect(() => {
        document.body.className = `${theme}-mode`;
    }, [theme]);
    useEffect(() => {
        if (sidebarRef.current) {
            const width = sidebarRef.current.offsetWidth;
            setSideBarWidth(width);
        }
    }, [isSidebarOpen, setSideBarWidth]);
    // const handleCreateClick = () => {
    //   mixpanelClient.track(MIXPANEL_EVENTS.AgentEntityCreateStart, {
    //     [MIXPANEL_PROPERTIES.UserId]: user?.id || '',
    //   });
    //   mixpanelClient.track(MIXPANEL_EVENTS.AgentEntityCreateStartFromSidebar, {
    //     [MIXPANEL_PROPERTIES.UserId]: user?.id || '',
    //   });
    //   navigate(PAGES.Create);
    // };
    const updatedSideBarData = sideBarMokData;
    const handleMenuClick = (link) => {
        if (link.action) {
            link.action();
        }
        else if (link.navigateToPage) {
            navigate(link.navigateToPage);
        }
        else if (link.childrenLinks) {
            setActiveParentLink(activeParentLink === link.linkName ? null : link.linkName);
        }
    };
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const onLogoutUser = () => __awaiter(void 0, void 0, void 0, function* () {
        yield logoutUser({}).unwrap();
    });
    const handleDownloadBtn = () => {
        dispatch(setShowSystemModal({
            modalName: SYSTEM_MODALS.downloadLauncher,
            show: true,
        }));
    };
    const handleJoinProClick = () => {
        setIsJoinProModalOpen(true);
    };
    const handleCloseJoinProModal = () => {
        setIsJoinProModalOpen(false);
    };
    const handleUpgradeSub = () => {
        handleUpgradeSubscription();
    };
    return (_jsx("div", { ref: sidebarRef, className: classNames(style.sidebarWrapper, {
            [style.sidebarWrapperMin]: !isSidebarOpen,
        }), children: _jsxs(_Fragment, { children: [_jsxs("div", { className: style.sidebarWrapper_top, children: [_jsxs("div", { className: classNames(style.sidebarWrapper_logoWrap, {
                                [style.sidebarWrapper_logoWrap_center]: !isSidebarOpen,
                            }), children: [isSidebarOpen && (_jsx("span", { onClick: () => {
                                        dispatch(setGlobalFilters({ search: '' }));
                                        navigate(PAGES.Discover);
                                    }, className: style.sidebarWrapper_logoWrap_logo, style: {
                                        color: 'var(--accent-color)',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                        paddingLeft: '20px',
                                        display: 'inline-block',
                                    }, children: "Altera" })), _jsx("img", { className: style.sidebarWrapper_logoWrap_toggle, src: '/assets/images/collapse.svg', alt: 'toggle', onClick: toggleSidebar })] }), _jsx("div", { className: style.sidebarWrapper_menu, children: updatedSideBarData.map((link) => (_jsx("div", { onClick: () => handleMenuClick(link), className: classNames(style.sidebarWrapper_menu_item, {
                                    [style.collapsed]: !isSidebarOpen,
                                }), children: _jsx(MenuButton, { iconImg: link.linkIcon, linkName: isSidebarOpen ? link.linkName : '', navigateTo: link.navigateToPage || '', isExpanded: isSidebarOpen, isCreateButton: link.linkName === 'Create' }) }, link.linkName))) })] }), user && (_jsxs("div", { children: [_jsxs("button", { className: style.joinProButton, onClick: user.access_tier === 'premium' ||
                                user.access_tier === 'researcher'
                                ? handleUpgradeSub
                                : handleJoinProClick, children: [_jsx("img", { src: '/assets/images/logo.svg', alt: 'Altera logo', className: style.buttonLogo }), _jsx("span", { children: isSidebarOpen
                                        ? user.access_tier === 'premium' ||
                                            user.access_tier === 'researcher'
                                            ? 'Manage Pro'
                                            : 'Upgrade'
                                        : '' })] }), isSidebarOpen && (_jsxs("button", { className: style.downloadBtn, onClick: handleDownloadBtn, children: [_jsx(FontAwesomeIcon, { icon: faArrowAltCircleDown }), _jsx("p", { children: "Download Launcher" })] })), _jsx("div", { className: style.userDataWrap, children: _jsxs("div", { className: style.userDataWrap_userData, children: [_jsx("div", { className: cn(style.tooltip, {
                                            [style.tooltip_sidebarClose]: !isSidebarOpen,
                                        }), children: _jsxs("div", { className: style.tooltip_item, onClick: onLogoutUser, children: [_jsx("img", { src: '/assets/images/img.png', alt: 'settings' }), _jsx("div", { children: "Logout" })] }) }), _jsx(Avatar, { image: user.picture, name: user.name, avatarSize: isSidebarOpen ? AvatarSize.full : AvatarSize.small }), isSidebarOpen && (_jsxs("div", { className: style.userDataWrap_userData_info, children: [_jsx("div", { className: style.userDataWrap_userData_info_name, children: user.name }), _jsx("div", { className: style.userDataWrap_userData_info_email, children: user.email })] }))] }) })] })), _jsx(Modal, { open: isJoinProModalOpen, onClose: handleCloseJoinProModal, children: _jsx(JoinProModal, { onJoin: handleUpgradeSub, context: 'sidebar' }) })] }) }));
};
